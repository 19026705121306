<template lang="pug">
div(class="header-home-page")
    div(class="container-header")        
        b-img(class=" image-logo" :src="DSG_logo", alt="DSG Logo")
        div(class="message-container" v-if="displayname.value")
            p.d-none.d-md-block Welcome {{displayname.value}} 
            b-dropdown(size="lg"  variant="link" toggle-class="text-decoration-none" no-caret)
                template(v-slot:button-content)
                    b-icon-person(class="icon-man")
                b-dropdown-item(class="d-md-none d-block") Welcome {{displayname.value}}
                b-dropdown-item-button(variant="danger" @click="logout") Logout
        div(v-else)
            b-spinner( variant="primary")
            
            
                
            
        
        

</template>

<script>

import DSG_logo from '@/assets/Images/DSG_logo.png';
import authModule from '../store/authenticator';


    export default {
        name: "HeaderHomePage",

        data() {
            return {
            isLocal: false,
            DSG_logo,
            buttonColor: 'rgb(118, 80, 137)',
            logindata: { ready: false },
            msalApp: null,
            isAdmin: false,
            promptLogin: false,
            }
        },
        methods:{
        async logout(){
            await authModule.removeAccount();
        },
        goToModularForm(){
            
            window.open('https://dsg-form.pages.dev/', '_blank');
        },
        goToRegistrationForm(){
            
            window.open('https://dsg-form.pages.dev/form-response/b0ebf55e-f927-ef11-840b-6045bd58192c/none', '_blank');
        },
        askAndProceed(){
            const checkNotificationPromise = () => {
                try {
                    Notification.requestPermission().then(result => {
                        if(result === "granted"){
                            console.log("Notification permission granted");
                            return true;
                        }
                        if(result === "denied"){
                            console.error("Notification permission denied");
                            return false;
                        }
                    });
                } catch (e) {
                    return false;
                }
                
                return true;
            }
            
            if (checkNotificationPromise()) {
                Notification.requestPermission().then(() => {

                });
            }

            // this.$router.push({path: '/home'});
        },
       
        // async test() {
        //     const {data} = await axios.post('/api/announcement', {});
        //     console.log(data)
        // },
    },
    computed: {
        displayname() {
        return authModule.displayname;
        },
        username() {
        return authModule.username;
        },

    },
    async mounted(){
        // console.log('home init')
        // await authModule.init();
        // await this.$root.init();
        this.isLocal = location.host.includes("localhost");
        this.askAndProceed();
    }
    }
</script>

<style lang="scss" scoped>
.header-home-page {
    background-color: #f8f9fa;
    color: #343a40;
    height: 10vh;
    width: 100%;
    .container-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        width: 80%;
        margin: 0 auto;
        .image-logo {
            width: 150px;
            
        }
        .message-container {
            display: flex;
            align-items: center;
            gap: 1rem;
            p {
                margin: 0 !important;
            }
        }
    }
    .icon-man {
        font-size: 1.5rem;
        color: black;
    }
}
@media (max-width: 768px) {
    .header-home-page {
        .container-header {
            width: 95%;
            .image-logo {
                width: 100px;
            }
        }
    }
}


</style>