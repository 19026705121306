<template lang="pug">
div(class="home-page-v2 h-100 w-100 d-flex flex-column overflow-auto")   
    HeaderHomePage
    div(class="card-container" v-if="displayname.value")
        div(v-for="option in options" :key="option.title" class="card")
            div(class="card-body" :style="{backgroundColor: option.color}")
                div(class="card-header-container")
                    b-icon(:icon="option.icon" size="2rem" :style="{color: option.fontColor}")
                    h5(class="card-title text-center"
                    :style="{color: option.fontColor}") {{option.title}}
                    
                p(class="card-text") {{option.description}}
            div(class="card-footer")
                button(class="w-100 button-footer" variant="outline" 
                @click="openLinks(option.link, option.outlink)"
                )  View
                    
                    
    div(v-else class="d-flex justify-content-center align-items-center" style="height: 90vh; width: 100vw;")
        b-spinner(variant="primary")
        
            
                
    
    
    
    

</template>

<script>
import HeaderHomePage from '../components/HeaderHomePage.vue';
import authModule from '../store/authenticator';
    export default {
        name: "HomePageV2",
        components: {
            HeaderHomePage
        },
        data() {
            return {
                isLocal: false,
            buttonColor: 'rgb(118, 80, 137)',
            logindata: { ready: false },
            msalApp: null,
            isAdmin: false,
            promptLogin: false,
                options: [
                    {
                        title: 'Announcements',
                        description: 'Access, create, and manage announcements',
                        icon: 'megaphone',
                        link: '/announcements?tab=pinned',
                        color:"#DBE9FE",
                        fontColor:"#2463EB"
                    },
                    {
                        title: 'Events',
                        description: 'Create new events for clients. Manage and view existing events',
                        icon: 'calendar-event',
                        link: '/events',
                        color:'#DCFCE7',
                        fontColor:"#2ECC71"
                    },
                    {
                        title: "Admin Dashboard",
                        description: "View the admin dashboard",
                        icon: 'speedometer2',
                        link: '/admindashboard',
                        color:'#FEF9C3',
                        fontColor:"#FFC107"

                    },
                    {
                        title: 'Mobile App Configuration',
                        description: 'Configure the mobile app (Change Photos, Texts, etc)',
                        icon: 'gear',
                        link: '/appconfiguration',
                        color:'#FBE7F3',
                        fontColor:"#FF1493"
                    },
                    {
                        title: 'Modular Form',
                        description: 'Design and distribute forms and surveys for data collection',
                        icon: 'file-earmark-text',
                        link: 'https://dsg-form.pages.dev/',
                        color:'#F3E8FF',
                        fontColor:"#8A2BE2",
                        outlink: true
                    },
                    // {
                    //     title: 'Registration Form',
                    //     description: 'Fill out the registration form',
                    //     icon: 'file-earmark-text',
                    //     link: 'https://dsg-form.pages.dev/form-response/b0ebf55e-f927-ef11-840b-6045bd58192c/none',
                    //     color:'#F3E8FF',
                    //     fontColor:"#8A2BE2"
                    // },
                ]
            }
        },
        methods:{
            
            openLinks(link, outlink){
                if(outlink){
                    window.open(link, '_blank');
                } else {
                    this.$router.push({path: link});
                }
            }
        },
        computed: {
        displayname() {
        return authModule.displayname;
        },
        username() {
        return authModule.username;
        },

    },
    }
</script>

<style lang="scss" scoped>
.card-container {
    width: 80%;
    margin: 0 auto;
    height: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 1rem;
    padding: 1rem;
    margin-top: 1rem;
    .card {
        height: 250px;
        transition: transform 0.5s;
        .card-body{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1rem;
        }

        .card-header-container {
            
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            h5 {
                margin: 0;
            }
           
        }
        .card-footer{
            background-color: white;
        .button-footer {
            background-color: white;
            border: 1px solid #E4E4E7;
            color: black !important;
            padding: 10px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 16px;
            margin: 4px 2px;
            cursor: pointer;
            border-radius: 5px;
        }
    }
    }
    .card:hover {
        transform: scale(1.05);
        transition: transform 0.5s;
    }
}
@media (max-width: 768px) {
    .card-container {
        width: 95%;
        
        .card {
            height: 200px;
        }
    }
}
</style>