<template lang="pug">
b-modal(v-model="showModal"  size="lg" scrollable title="View Announcement")
    div(class=" p-2")
        h3(class="d-md-block d-none" ) {{ announcement.title }}
        h4(class="d-md-none d-block" ) {{ announcement.title }}
        p Published Date: {{ new Date(announcement.createdAt).toLocaleDateString("en-GB") }}
        div(v-if="announcement.isPinned === 1" style="gap: 10px; display: flex; align-items: center; margin-bottom: 10px;") 
            b-badge(variant="primary") Pinned
        
            b-badge(:variant="new Date(announcement?.expiryDate) > new Date() ? 'success' : 'danger'")  {{ new Date(announcement?.expiryDate) > new Date() ? 'Active' : 'Expired' }}
            span Pinned {{new Date(announcement.expiryDate) > new Date() ? 'until' : 'expired after'}}  {{announcement?.expiryDate ? new Date(announcement?.expiryDate).toLocaleDateString("en-GB") : 'N/A'}}
            
        
        hr
        div(v-html="announcement.body")
        p Recipients:
        div(class="announcement-recipients d-flex flex-wrap " style="gap: 10px")
                    div(v-for="recipientId in announcement?.groups" 
                        :key="recipientId?.id" class="recipient-badge")
                        p.m-0 {{ recipientId?.name }}
        div(v-if="announcement?.imgObj?.images?.length > 0" class="d-flex flex-wrap my-4")
            div(v-for="image in announcement?.imgObj?.images" :key="image?.id" class="d-flex flex-column image-cont")
                img(:src="image?.downloadURL" class="img-fluid" alt="preview")
                
    template(v-slot:modal-footer class="mt-5")
            b-button(variant="primary" @click="showModal = false") Close

            

</template>

<script>
    export default {
        props: {
            showViewDialog: {
                type: Boolean,
                required: true,
            },
            announcement: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                title: "",
                body: "",
                isPinned: false,
                selectedGroup: [],
                error: {
                    title: "",
                    body: "",
                },
                loading: false,
                expiryDate: "",
                expiryTime: "",
            };
        },
        computed: {
            showModal: {
                get() {
                    return this.showViewDialog;
                },
                set(val) {
                    this.$emit("update:showViewDialog", val);
                },
            },
        },
        watch: {
            showEditDialog(val) {
                if (val) {
                    this.title = this.announcement.title;
                    this.body = this.announcement.body;
                    this.isPinned = this.announcement.isPinned === 1 ? true : false;
                    this.selectedGroup = this.announcement.groups;
                    if (this.announcement.expiryDate && this.announcement.expiryDate !== "") {
                        const expiryDateTime = new Date(this.announcement.expiryDate);
                        expiryDateTime.setDate(expiryDateTime.getDate() + 1);
                        this.expiryDate = expiryDateTime.toISOString().split("T")[0];
                        this.expiryTime = expiryDateTime.toTimeString().split(" ")[0];
                    }
                }
            },
        },
        
    }
</script>

<style lang="scss" scoped>
.recipient-badge {
    background-color: #eef7ff;
    color: black;
    padding: 5px;
    border-radius: 5px;
    font-size: 15px;
    border: 1px solid #bad1ec;
    p {
        font-size: 12px;
    }
}
.image-cont {
    
    position: relative; 
    border: 1px solid #ACB5BD;
    padding: 5px;
    border-radius: 10px;
    img {
        border-radius: 10px;
        width: 200px;
        height: 200px;
    }
}
</style>