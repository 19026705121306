<template lang="pug">
div(class="announcement-page")
    Sidebar
    div(class="mobile-nav d-lg-none")
        img(:src="DSG_logo" alt="DSG Logo")
        b-button(class="bg-8967b3" @click="$router.push('/')") Go Home

    div(class="d-flex flex-column announcement-cont" 
        :style="{ width: expanded ? '80%' : '90%' }")
        
        TopBar(:currentTab="currentTab" @tabChange="changeTab" @createAnnouncement="showCreateDialog = true")
        div(class=" px-4 mt-2 d-flex justify-content-end" v-if="!loading")
            v-select(:options="sortBy" v-model="sortBySelected"  placeholder="Sort By" style="width: 200px")
            
        div(class="announcements-list w-100" v-if="!loading")
            div(v-for="announcement in paginatedAnnouncements" 
                :key="announcement.id" class="announcement-item")
                div(class="announcement-title d-flex justify-content-between w-100")
                    h4.m-0 {{announcement?.title}}
                    div(class="d-flex align-content-center" style="gap: 15px")
                        div(:class="['local-badge', 'd-none', 'd-md-block', { 'bg-expired': new Date(announcement?.expiryDate) < new Date() }]" 
                            v-if="announcement?.isPinned").m-0.small Pinned till {{announcement?.expiryDate ? new Date(announcement?.expiryDate).toLocaleDateString("en-GB") : 'N/A'}}
                        div(class="buttons d-flex align-content-center" style="gap: 5px")
                            b-button(variant="info" @click="setShowViewDialog(announcement)")
                                b-icon(icon="eye")
                            //- b-button(variant="" disabled v-if="announcement?.isPinned" class="d-block d-md-none")
                            //-     b-icon(icon="pin")
                            b-button(variant="primary" @click="setEdit(announcement)")
                                b-icon(icon="pencil")
                            b-button(variant="danger" @click="deleteAnnouncement(announcement)")
                                b-icon(icon="trash") 

                p(class="created-date") Created on {{ new Date(announcement?.createdAt).toLocaleDateString('en-GB') }}
                
                div(class="announcement-body" v-html="truncateHtml(announcement?.body, 20)")

                
                div(class="announcement-recipients d-flex flex-wrap" style="gap: 10px")
                    div(v-for="recipientId in announcement?.groups" 
                        :key="recipientId?.id" class="recipient-badge")
                        p.m-0 {{ recipientId?.name }}
            div(v-if="paginatedAnnouncements.length === 0" class="d-flex flex-column w-100 my-5 h-50" 
                style="align-items: center; justify-content: center; gap: 10px")
                p.m-0 No announcements found
        
        div(v-else class="d-flex flex-column w-100 justify-content-center align-items-center h-50" 
            style="align-items: center; justify-content: center; gap: 10px")
            b-spinner(variant="primary")
            p.m-0 Loading...

        
        div(class="pagination-controls d-flex justify-content-center mt-3 align-content-center text-center" style="gap: 10px" v-if="totalPages > 1 && !loading")
            b-button(@click="prevPage" :disabled="currentPage === 1") Previous
            p.m-0.text-center {{ currentPage }} / {{ totalPages }}
            b-button(@click="nextPage" :disabled="currentPage === totalPages") Next
        EditAnnouncementDialog(:showEditDialog.sync="showEditDialog" :groups="groups" :announcement="selectedAnnouncement" @updated="fetchAnnouncements")
        ViewDialogAnnouncement(:showViewDialog.sync="showViewDialog" :announcement="selectedAnnouncement")
        AddAnnouncementDialog(:showCreateDialog.sync="showCreateDialog" :groups="groups" @created="fetchAnnouncements")
</template>

<script>
import axios from "axios";
import Sidebar from '../../components/v2/Sidebar.vue';
import TopBar from '../../components/v2/announcements/TopBar.vue';
import DSG_logo from '@/assets/Images/DSG_logo.png';
import { mapActions } from 'vuex';
import EditAnnouncementDialog from '@/components/announcements/EditAnnouncementDialog.vue';
import ViewDialogAnnouncement from "../../components/v2/announcements/ViewDialogAnnouncement.vue";
import AddAnnouncementDialog from "../../components/announcements/AddAnnouncementDialog.vue";

export default {
    components: {
        Sidebar,
        TopBar,
        EditAnnouncementDialog,
        ViewDialogAnnouncement,
        AddAnnouncementDialog,
    },
    data() {
        return {
            currentTab: 'pinned',
            groups: [],
            DSG_logo,
            loading: false,
            currentPage: 1,
            itemsPerPage: 4, 
            showEditDialog: false,
            selectedAnnouncement: {},
            showViewDialog: false,
            showCreateDialog: false,
            sortBy:[
                {label:"Latest", value:"latest"},
                {label:"Oldest", value:"oldest"},
            ],
            sortBySelected: {label:"Latest", value:"latest"},

        };
    },
    computed: {
        expanded() {
            return this.$store.state.expandedSidebar;
        },
        screenWidth() {
            return window.innerWidth;
        },
        announcements() {
            return this.$store.state.announcements;
        },
        totalPages() {
            return Math.ceil(this.announcements.length / this.itemsPerPage);
        },
        sortedAnnouncements() {
        let sorted = [...this.announcements];

        
        if (this.sortBySelected.value === "latest") {
        
            sorted.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        } else if (this.sortBySelected.value === "oldest") {
            sorted.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        }

        return sorted;
    },
    
    paginatedAnnouncements() {
        const start = (this.currentPage - 1) * this.itemsPerPage;
        return this.sortedAnnouncements.slice(start, start + this.itemsPerPage);
    }
    },
    methods: {
        // async updatedAnnouncement(ann) {
        //     const id = ann.crb5c_fowannouncementid;
        //     const index = this.announcements.findIndex((announcement) => announcement.id === id);
        //     if (index === -1) return;


        //     const updatedAnnouncement = {
        //         id,
        //         title: ann.crb5c_title,
        //         body: JSON.parse(ann.crb5c_body),
        //         isPinned: !!ann.crb5c_ispinned,
        //         groups: ann.groups || [],
        //         createdAt: new Date(ann.createdon).toLocaleDateString('en-GB'),
        //         expiryDate: ann.crb5c_expiry ? new Date(ann.crb5c_expiry).toISOString() : "",
        //         imgObj: ann?.crb5c_images ? JSON.parse(ann.crb5c_images) : [],
        //     };
        //     this.$store.commit("updateAnnouncement", {
        //         index,
        //         updatedAnnouncement,
        //     });

        //     this.showEditDialog = false;


        // },
        
        async deleteAnnouncement(item) {
            let choice = confirm('Are you sure you want to delete this announcement?');
            if (!choice) return;
            try {
                let index = this.announcements.findIndex(announcement => announcement.id === item.id);
                let res = await axios.put(`/api/announcement/v2/delete`, { id: item.id });
                if (res.status === 200) {
                    this.announcements.splice(index, 1);
                    this.$root.$bvToast.toast("Announcement deleted", {
                        title: "Success",
                        variant: "success",
                        solid: true,
                    });
                }
            }
            catch (e) {
                console.log(e)
                this.$root.$bvToast.toast("Error deleting announcement", {
                    title: "Error",
                    variant: "danger",
                    solid: true,
                });
            }

        },
        truncateHtml(html, count) {
            let div = document.createElement("div");
            div.innerHTML = html;
            let text = div.textContent;
            return text.split(" ").slice(0, count).join(" ") + "...";
        },
        setShowViewDialog(announcement) {
            this.selectedAnnouncement = announcement;
            console.log(this.selectedAnnouncement);
            this.showViewDialog = true;
        },

        setEdit(announcement) {
            this.selectedAnnouncement = announcement;
            console.log(this.selectedAnnouncement);
            this.showEditDialog = true;
        },
        getGroupName(id) {
            const group = this.groups.find(group => group.id === id);
            return group ? group.name : 'Unknown Group';  
        },
        ...mapActions(['getEveryGroup']),
        async fetchAnnouncements() {
    this.sortBySelected = {label: "Latest", value: "latest"};
    this.loading = true;
    try {
        const response = await axios.get(`/api/announcement/v2?type=${this.currentTab}`);
        let formatedAnnouncements = [];
        const { announcements, token } = response.data;

        // Create an array of promises for image fetch requests
        const imageFetchPromises = announcements.map(async (announcement) => {
            let groupIds = JSON.parse(announcement.crb5c_recipient);
            let imgObj = announcement?.crb5c_images ? JSON.parse(announcement.crb5c_images) : [];

            if (imgObj?.images) {
                // For each image, fetch the download URL
                for (let i = 0; i < imgObj.images.length; i++) {
                    let image = imgObj.images[i];
                    const url = `https://graph.microsoft.com/v1.0/drives/${process.env.VUE_APP_SHAREPOINT_ID}/items/${image?.id}`;
                    const res = await axios.get(url, {
                        headers: { Authorization: `Bearer ${token}` }
                    });
                    imgObj.images[i].downloadURL = res.data['@microsoft.graph.downloadUrl'];
                }
            }

            let groups = [];
            groupIds.forEach(groupId => {
                let group = this.groups.find(group => group.id === groupId);
                if (group) {
                    groups.push(group);
                }
            });

            formatedAnnouncements.push({
                id: announcement.crb5c_fowannouncementid,
                title: announcement.crb5c_title,
                body: JSON.parse(announcement.crb5c_body),
                isPinned: announcement.crb5c_ispinned,
                groups: groups,
                createdAt: announcement.createdon,
                expiryDate: announcement.crb5c_expiry ? new Date(announcement.crb5c_expiry) : "",
                imgObj: imgObj,
            });
        });

        // Wait for all image fetch promises to complete
        await Promise.all(imageFetchPromises);

        // Commit the formatted announcements to the store after all images are fetched
        this.$store.commit("setAnnouncements", formatedAnnouncements);
        this.$store.commit("setTabType", this.currentTab);
        
    } catch (error) {
        console.error("Error fetching announcements:", error);
    } finally {
        this.loading = false;
    }
}
,
        async changeTab(tab) {
            this.currentTab = tab;
            this.$router.push({ query: { tab } });
            this.currentPage = 1; 
            await this.fetchAnnouncements();
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        }
    },
    async mounted() {
        let tab = this.$route.query.tab;
        this.loading = true;
        const { sessions } = await this.getEveryGroup();
        
        if (!sessions || sessions.length === 0) {
            return;
        }
        
        const sessionMap = sessions.map(item => ({
            id: item.crb5c_fow_session_scheduleid,
            name: item.crb5c_session_id,
        }));
        let sortListGroupSession = this.$root.sortSessionGroupNameByDay(sessionMap);
        sortListGroupSession.unshift({ id: 'All', name: 'All' });
        
        this.groups = sortListGroupSession;
        if (tab) {
            this.currentTab = tab;
            if(tab === this.$store.state.tabType && this.$store.state.announcements.length > 0){
                this.loading = false;
                return;
            }
        }
        await this.fetchAnnouncements();
        this.loading = false;
    }
};
</script>

<style lang="scss" scoped>
.announcement-page {
    display: flex;
    height: 100vh;
    .mobile-nav {
        display: none;
    }
    .announcement-cont {
        height: 100vh;
    }
}
.announcement-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: wrap;
    text-align: start;
}
.announcement-item {
    padding: 10px;
    border: 1px solid #ccc;
    width: 95%;
    margin: 10px auto;
    display: flex;
    align-items: start;
    flex-direction: column;
    border-radius: 5px;
}
.local-badge {
    background-color: #eef7ff;
    color: black;
    padding: 5px;
    border-radius: 5px;
    font-size: 15px;
    white-space: nowrap;
    border: 1px solid #bad1ec;
}
.created-date {
    font-size: 15px;
    color: #666;
    margin: 0px;
}
.recipient-badge {
    background-color: #eef7ff;
    color: black;
    padding: 5px;
    border-radius: 5px;
    font-size: 15px;
    border: 1px solid #bad1ec;
    p {
        font-size: 12px;
    }
}
.pagination-controls {
    margin-top: 20px;
}
@media screen and (max-width: 1000px) {
    .announcement-page {
        flex-direction: column;
        .mobile-nav {
            display: flex;
            height: 10vh;
            justify-content: space-between;
            align-items: center;
            padding: 0 20px;
            width: 100%;
            img {
                height: 50px;
            }
        }
        .announcement-cont {
            height: 90vh;
            overflow-y: scroll;
            width: 100% !important;
            
        }
        .announcement-title {
            h4 {
                font-size: 15px;
            }
        }
        .created-date {
            font-size: 12px;
        }
    }
}
.announcement-body {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: start;
}
.bg-expired {
    background-color: #f8d7da;
    color: #721c24;
}
</style>
