<template lang="pug">
b-modal(v-model="showModal" title="Create A New Announcement" size="lg" no-close-on-backdrop scrollable)
    div(class="w-100")
        b-form-group(label="Title")
            b-form-input(v-model="announcement.title" placeholder="Enter Title" required :class="{ 'is-invalid': announcement.title.length < 5 && announcement.title.length > 0 }" )
            p(v-if="announcement.error.title" class="text-danger") {{ announcement.error.title }}
        
        b-form-group(label="Body")
            div(:class="{ 'invalidVueEditor': announcement.body.length < 5 && announcement.body.length > 0 }")
                VueEditor(v-model="announcement.body" :editorToolbar="customToolbar" placeholder="Enter Body" required)
            p(v-if="announcement.error.body" class="text-danger") {{ announcement.error.body }}
        b-form-group(label)
            v-select( id="event-group"  :options="groups" :reduce="item => ({ id: item.id, name: item.name })" multiple label="name" placeholder="Select Group" :class="{'invalid': announcement.selectedGroup.length === 0}" v-model="announcement.selectedGroup")
        div(class="d-flex justify-content-between")
            p Pinned 
            b-form-checkbox(v-model="announcement.isPinned" switch) 
        hr(v-if="announcement.isPinned")
        div(class="d-flex flex-column" v-if="announcement.isPinned")
            p Expiry Date for Pinned Announcement
            b-form-datepicker(v-model="announcement.expiryDate" :min="new Date()" placeholder="Select a Date" :class="{'invalid': announcement.expiryDate === ''}")
        div(class="d-flex flex-column my-3" v-if="announcement.isPinned")
            p Expiry Time for Pinned Announcement
            b-form-timepicker(v-model="announcement.expiryTime" placeholder="Select a Time" :class="{'invalid': announcement.expiryTime === ''}" locale="en" )
        div(class="d-flex flex-column my-1")
            div(class="d-flex flex-row flex-wrap")
                div(v-for="(image, index) in announcement.images" :key="index" class="d-flex flex-row preview")
                    div(class="d-flex flex-column image-cont")
                        img(:src="fileToImage(image)" class="img-fluid" alt="preview")
                        b-button(@click="announcement.images.splice(index, 1)" variant="danger") Remove
            p Select Images for Announcement
            div(class="d-flex flex-wrap")
                b-form-file(v-model="announcement.images" accept="image/*" multiple)
            div(class="d-flex flex-row my-3 justify-content-between" v-if="announcement.images?.length > 0")
                p Downloadable
                b-form-checkbox(v-model="announcement.imageDownloadable" switch)
    
    template(v-slot:modal-footer)
        b-button(@click="showModal = false" :disabled="announcement.loading") Cancel
        b-button(@click="submit" variant="primary" :disabled="!validateTitle || !validateBody || announcement.loading") 
            span(v-if="announcement.loading") 
                b-spinner(type="grow" small)
            span(v-else) Create
        
</template>

<script>
import { VueEditor } from "vue2-editor";
import { mapActions } from "vuex";
import axios from "axios";

export default {
    components: {
        VueEditor,
    },
    props: ["showCreateDialog", "groups"],

    data() {
        return {
            customToolbar: [
                ["bold", "italic", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" },
                ],
                ["link"],
                ["clean"],
            ],
            announcement: {
                title: "",
                body: "",
                selectedGroup: [],
                isPinned: false,
                expiryDate: "",
                expiryTime: "",
                error: {
                    title: "",
                    body: "",
                },
                loading: false,
                images: [],
                imageDownloadable: false,
            },
            existingAnnouncement: {},
            skipLocalStorageUpdate: false,
        };
    },
    methods: {
        fileToImage(file) {
            return URL.createObjectURL(file);
        },
        consoleLog() {
            console.log(this.announcement.title);
            console.log(this.$root);
        },
        ...mapActions(["getEveryGroup"]),
        async submit() {
            if (this.announcement.selectedGroup.length === 0) {
                this.$root.$bvToast.toast("Please select a group", {
                    title: "Error",
                    variant: "danger",
                    solid: true,
                });
                return;
            }
            if (this.validateTitle && this.validateBody) {
                if (this.announcement.isPinned && (this.announcement.expiryDate === "" || this.announcement.expiryTime === "")) {
                    this.$root.$bvToast.toast("Please select an expiry date and time", {
                        title: "Error",
                        variant: "danger",
                        solid: true,
                    });
                    return;
                }
                this.announcement.loading = true;
                try {
                    const formData = new FormData();

                    formData.append("title", this.announcement.title);
                    formData.append("body", JSON.stringify(this.announcement.body));
                    formData.append("isPinned", this.announcement.isPinned);
                    formData.append("groupIds", JSON.stringify(this.announcement.selectedGroup.map((item) => item.id)));
                    formData.append(
                        "expiryDate",
                        this.announcement.isPinned
                            ? new Date(this.announcement.expiryDate + " " + this.announcement.expiryTime).toISOString()
                            : null
                    );
                    formData.append("imageDownloadable", this.announcement.imageDownloadable);

                    this.announcement.images.forEach((image) => {
                        formData.append(`images`, image);
                    });
                    console.log(formData);

                    const res = await axios.post("/api/announcement/v2", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    });

                    if (res.status === 200) {
                        this.showModal = false;
                        this.announcement = {
                            title: "",
                            body: "",
                            selectedGroup: [],
                            isPinned: false,
                            expiryDate: "",
                            expiryTime: "",
                            error: {
                                title: "",
                                body: "",
                            },
                            loading: false,
                            images: [],
                            imageDownloadable: false,
                        };


                        this.$emit("created");
                        localStorage.removeItem("announcement");
                        this.$root.$bvToast.toast("Announcement created successfully", {
                            title: "Success",
                            variant: "success",
                            solid: true,
                        });
                    }
                } catch (error) {
                    console.error(error);
                    this.$root.$bvToast.toast("An error occurred", {
                        title: "Error",
                        variant: "danger",
                        solid: true,
                    });
                } finally {
                    this.announcement.loading = false;
                }
            }
        },
    },
    computed: {
        showModal: {
            get() {
                return this.showCreateDialog;
            },
            set(value) {
                this.$emit("update:showCreateDialog", value);
            },
        },
        validateTitle() {
            return this.announcement.title.length >= 5;
        },
        validateBody() {
            return this.announcement.body.length >= 5;
        },
    },
    watch: {
        "announcement.title": function (newTitle) {
            this.announcement.error.title = newTitle.length < 5 ? "Title must be at least 5 characters" : "";
        },
        "announcement.body": function (newBody) {
            this.announcement.error.body = newBody.length < 5 ? "Body must be at least 5 characters" : "";
        },
        showModal(newVal) {
        if (!newVal) {
            this.skipLocalStorageUpdate = true;
            this.announcement = {
                title: "",
                body: "",
                selectedGroup: [],
                isPinned: false,
                expiryDate: "",
                expiryTime: "",
                error: {
                    title: "",
                    body: "",
                },
                loading: false,
                images: [],
                imageDownloadable: false,
                
            };
        } else {
            this.skipLocalStorageUpdate = false;
            const savedAnnouncement = JSON.parse(localStorage.getItem("announcement"));
            if (savedAnnouncement) {
                const choice = confirm(
                    `Do you want to continue with the previous announcement? Title: ${savedAnnouncement.title}`
                );
                
                if (choice) {
                    this.announcement = savedAnnouncement;
                } else {
                    localStorage.removeItem("announcement");
                }
            }
        }
    },
    announcement: {
        handler(newVal) {
            if (this.skipLocalStorageUpdate) return; 
            const announcementToSave = { ...newVal };
            announcementToSave.images = [];
            localStorage.setItem("announcement", JSON.stringify(announcementToSave));
        },
        deep: true,
    },

    },
    
};
</script>

<style lang="scss" scoped>
.invalidVueEditor {
    border: 1px solid red;
}
.preview {
    margin: 10px;
    position: relative; 
    
    .image-cont {
        margin: 10px;
        position: relative; 
        border: 1px solid #ACB5BD;
        padding: 5px;
        border-radius: 10px;
        img {
            width: 200px;
            height: 200px;
            object-fit: cover; 
            
            
        }
        button {
            position: absolute;
            top: 5px; 
            right: 5px; 
            border: none; 
            z-index: 1;
           
        }
    }
}


</style>
