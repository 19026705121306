<template lang="pug">
b-modal(v-model="showModal" title="Edit Announcement" size="lg" scrollable) 
    div(class="w-100 ")
        b-form-group(label="Title")
            b-form-input(v-model="title" placeholder="Enter Title" required :class="{ 'is-invalid': title.length < 1 && title.length > 0 }" )
        b-form-group(label="Body")
            div(:class="{ 'invalidVueEditor': body.length < 1 && body.length > 0 }")
                VueEditor(v-model="body" :editorToolbar="customToolbar" placeholder="Enter Body" required)
    
        b-form-group(label)
            v-select( id="event-group"  :options="groups" :reduce="item => ({ id: item.id, name: item.name })" multiple label="name" placeholder="Select Group" :class="{'invalid': selectedGroup.length === 0}" v-model="selectedGroup")
        div(class="d-flex justify-content-between")
            p Pinned 
            b-form-checkbox(v-model="isPinned" switch) 
        hr(v-if="isPinned")
        div(class="d-flex flex-column" v-if="isPinned")
            p Expiry Date for Pinned Announcement
            b-form-datepicker(v-model="expiryDate" :min="new Date()" placeholder="Select a Date" :class="{'invalid': expiryDate === ''}")
        div(class="d-flex flex-column my-3" v-if="isPinned")
            p Expiry Time for Pinned Announcement
            b-form-timepicker(v-model="expiryTime" placeholder="Select a Time" :class="{'invalid': expiryTime === ''}" locale="en" )
        p Select Images for Announcement
        div(class="d-flex flex-wrap")
            b-form-file(v-model="newImages" accept="image/*" multiple)
        div(class="d-flex flex-row my-3 justify-content-between" v-if="imgObj?.images?.length > 0 || newImages?.length > 0")
            p Downloadable
            b-form-checkbox(v-model="imgObj.imageDownloadable" switch)

        p(v-if="newImages?.length > 0") New Images
        div(class="d-flex flex-wrap w-100")
            div(v-for="(image, index) in newImages" :key="index" class="d-flex flex-row preview" v-if="newImages?.length > 0")
                div(class="d-flex flex-column image-cont")
                    img(:src="fileToImage(image)" class="img-fluid" alt="preview")
                    b-button(@click="newImages.splice(index, 1)" variant="danger") Remove
        p(v-if="imgObj?.images?.length > 0") Existing Images    
        div(class="d-flex flex-wrap w-100")
            div(v-for="(image, index) in imgObj.images" :key="index" class="d-flex flex-row preview" v-if="imgObj?.images?.length > 0")
                div(class="d-flex flex-column image-cont")
                    img(:src="image.downloadURL" class="img-fluid" alt="preview")
                    b-button(@click="imgObj.images.splice(index, 1)" variant="danger") Remove

    template(v-slot:modal-footer)
        b-button(@click="showModal = false" :disabled="loading") Cancel
        b-button(@click="submit" variant="primary" :disabled="!validateTitle || !validateBody || loading") 
            span(v-if="loading") 
                b-spinner(type="grow" small)
            span(v-else) Update
</template>


<script>
import { VueEditor } from "vue2-editor";
import axios from "axios";
export default {
    components: {
        VueEditor,
    },
    props: ["showEditDialog", "groups", "announcement"],

    data() {
        return {

            customToolbar: [
                ["bold", "italic", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" },
                ],
                ["link"],
                ["clean"],
            ],
            title: "",
            body: "",
            selectedGroup: [],
            isPinned: false,
            error: {
                title: "",
                body: "",
            },
            loading: false,
            expiryDate: "",
            expiryTime: "",
            imgObj:{images:[],imageDownloadable:false},
            newImages: [],
            
        };
    },
    // async mounted() {
    //     if (this.announcement) {
            
    //         this.title = this.announcement.title;
    //         this.body = this.announcement.body;
    //         this.isPinned = this.announcement.isPinned === 1 ? true : false;
    //         this.selectedGroup = this.announcement.groups;
    //         if (this.announcement.expiryDate && this.announcement.expiryDate !== "") {
    //             const expiryDateTime = new Date(this.announcement.expiryDate);
    //             expiryDateTime.setDate(expiryDateTime.getDate() + 1);
    //             this.expiryDate = expiryDateTime.toISOString().split("T")[0];
    //             this.expiryTime = expiryDateTime.toTimeString().split(" ")[0];
    //         }
            
    //     }    

    // },
    methods: {
        fileToImage(file) {
            return URL.createObjectURL(file);
        },
        
        consoleLog() {
            console.log(this.title)
            console.log(this.$root)
        },
        
        async submit () {
            if (this.validateTitle && this.validateBody) {
                this.loading = true;
                try{
                // const data = {
                //     id: this.announcement.id,
                //     title: this.title,
                //     body: JSON.stringify(this.body),
                //     isPinned: this.isPinned,
                //     groupIds: JSON.stringify(this.selectedGroup.map((item) => item.id),),
                //     expiryDate: this.isPinned ? new Date(this.expiryDate + " " + this.expiryTime).toISOString() : null,
                // };
                const formData = new FormData();
                formData.append("id", this.announcement.id);
                formData.append("title", this.title);
                formData.append("body", JSON.stringify(this.body));
                formData.append("isPinned", this.isPinned);
                formData.append("groupIds", JSON.stringify(this.selectedGroup.map((item) => item.id)));
                formData.append("expiryDate", this.isPinned ? new Date(this.expiryDate + " " + this.expiryTime).toISOString() : null);
                formData.append("imageDownloadable", this.imgObj.imageDownloadable);
                formData.append("oldImages", JSON.stringify(this.imgObj.images ? this.imgObj.images.map((item) => item.id) : []));
                this.newImages.forEach((image) => {
                    formData.append("images", image);
                });
                

                const res = await axios.put("/api/announcement/v2", 
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
                );
                console.log(res)
                if (res.status === 200) {
                    this.showModal = false;
                    this.$emit("updated", res.data?.data);
                }
                this.$root.$bvToast.toast("Announcement updated", {
                    title: "Success",
                    variant: "success",
                    solid: true,
                });
            }
            catch(e){
                console.log(e)
                this.$root.$bvToast.toast("Error updating announcement", {
                    title: "Error",
                    variant: "danger",
                    solid: true,
                });
            }
            finally{
                this.loading = false;
            }
            }
        },
    },
    computed: {


        showModal: {
            get() {
                return this.showEditDialog;
            },
            set(value) {
                this.$emit("update:showEditDialog", value);
            },
        },
        validateTitle() {
            if(!this.title) return
            return this.title.length >= 1;
        },
        validateBody() {
            if(!this.body) return
            return this.body.length >= 1;
        },
    },
    watch: {
    showModal(newVal) {
        if (!newVal) {
            this.title = "";
            this.body = "";
            this.isPinned = false;
            this.error = {
                title: "",
                body: "",
            };
            this.selectedGroup = [];
            this.expiryDate = "";
            this.expiryTime = "";
            this.imgObj = {images: [], imageDownloadable: false}; 
            this.newImages = [];
        } else {
            this.title = this.announcement.title;
            this.body = this.announcement.body;
            this.isPinned = this.announcement.isPinned === 1 ? true : false;
            this.selectedGroup = this.announcement.groups;
            if (this.announcement.expiryDate && this.announcement.expiryDate !== "") {
                const expiryDateTime = new Date(this.announcement.expiryDate);
                expiryDateTime.setDate(expiryDateTime.getDate() + 1);
                this.expiryDate = expiryDateTime.toISOString().split("T")[0];
                this.expiryTime = expiryDateTime.toTimeString().split(" ")[0];
            }

            
            this.imgObj = JSON.parse(JSON.stringify(this.announcement.imgObj));
        }
    },


        announcement(newVal) {
            if (newVal) {
                this.title = newVal.title;
                this.body = newVal.body;
                this.isPinned = newVal.isPinned === 1 ? true : false;
                this.selectedGroup = newVal.groups;
                if (newVal.expiryDate && newVal.expiryDate !== "") {
                    const expiryDateTime = new Date(newVal.expiryDate);
                    expiryDateTime.setDate(expiryDateTime.getDate() + 1);
                    this.expiryDate = expiryDateTime.toISOString().split("T")[0];
                    this.expiryTime = expiryDateTime.toTimeString().split(" ")[0];
                }
            }
        },
    },
    
};
</script>
<style lang="scss" scoped>
.image-cont {
    margin: 10px;
    position: relative;
    padding: 5px;
    border: 1px solid #ACB5BD;
    border-radius: 10px;
    width:100%;
    
    img {
        width: 200px;
        height: 200px;
        object-fit: cover;
    }

    button {
        position: absolute;
        top: 5px;
        right: 5px;
        border: none;
        z-index: 1;
    }
}

.preview {
    margin: 10px;
    position: relative;
}
</style>
