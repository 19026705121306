<template lang="pug">
div(class="topbar")
    div(class="title")
        h2.m-0 Announcements
        b-button(class="bg-8967b3" @click="$emit('createAnnouncement')" ) Create Announcement
    div(class="tabs")
        div(v-for="tab in tabs" :key="tab.value" :class="{active: currentTab === tab.value}"
        class="tab" @click="$emit('tabChange', tab.value)") {{tab.name}}
        
        
</template>

<script>
    export default {
        props:{
            currentTab: String
        },
        data(){
            return {
                tabs: [
                    {name: 'Pinned', value: 'pinned'},
                    {name:'Regular', value: 'regular'},
                    {name: 'All', value: 'all'},
                    
                ],
                
            }
        },
        
    }
</script>

<style lang="scss" scoped>
.topbar {
    background-color: white;
    height: 15%;
    border-bottom: 0.5px solid #B1B2B5;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start ;
    width: 100%;
    padding: 20px 40px;
    .title{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h2 {
            color: black;
        }
        .bg-8967b3 {
        background-color: #522D6D;
        color: white;
        border: none;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;
        &:hover {
            background-color: #6b4f9b;
        }
    }
    }
    .tabs{
        width: 100%;
        display: flex;
        gap: 10px;
        .tab{
            padding: 10px 20px;
            transition: background-color 0.3s ease;
            
            cursor: pointer;
            &:hover{
                background-color: #f2f2f2;
            }
            &.active{
                
                
                border-bottom: 2px solid #6b4f9b;
            }
        }
    }
    
    
}

@media screen and (max-width: 1000px){
    .topbar{
        height:fit-content;
        .title{
            flex-direction: column;
            align-items: start;
            gap: 10px;
            
            h2{
                font-size: 1.5rem;
                margin-bottom: 10px;
            }
            .bg-8967b3{
                padding: 5px 10px;
                width: 100%;
            }
        }
        .tabs{
            margin-top: 2.5rem;
            .tab{
                padding: 5px 10px;
            }
        }
    }
}

</style>