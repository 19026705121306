<template lang="pug">
transition(name="sidebar-transition")
    div(class="sidebar" v-if="expanded")
        div(class="image-container")
            img(:src="DSG_logo" alt="DSG Logo" @click="toggleSidebar") 
        div(class="links-container")
            router-link(v-for="path in paths" :key="path.name" :to="path.path" class="nav-link" :class="{active: activeRoute === path.path}")
                div(class="icon")
                    b-icon(:icon="path.icon")
                p {{path.name}}
        div(class="logout text-white d-flex flex-column align-items-start justify-content-center p-4" v-if="displayname?.value")
            p.m-0.text-sm.small Logged in As
            p {{displayname?.value}}
            b-button(variant="danger" @click="logout" class="w-100") Logout
        div(class="logout text-white d-flex flex-column align-items-start justify-content-center p-4" v-else)
            p.text-sm.small Please Wait
            b-spinner(size="sm")
    div(class="minimized" v-else)
        div(class="image-container")
            img(:src="DSG_SMALL" alt="DSG Logo" @click="toggleSidebar" class="w-50")
        div(class="icons-container text-white")
            router-link(v-for="path in paths" :key="path.name" :to="path.path" class="nav-link" :class="{active: activeRoute === path.path}")
                div(class="icon")
                    b-icon(:icon="path.icon")
        div(class="expand-button")
            b-button(variant="light" @click="toggleSidebar" class="w-50") 
                b-icon(:icon="expanded ? 'chevron-double-left' : 'chevron-double-right'")
    
</template>

<script>
import DSG_logo from '@/assets/Images/DSG_logo.png';
import dsg_small from "@/assets/Icons/dsg.png";
import authModule from '../../store/authenticator';

export default {
  data() {
    return {
      DSG_logo: DSG_logo,
      DSG_SMALL: dsg_small,
      paths: [
        { name: 'Home', path: '/', icon: 'house' },
        { name: 'Announcements', path: '/announcements', icon: 'megaphone' },
        { name: 'Events', path: '/events', icon: 'calendar' },
      ],
    };
  },
  computed: {
    expanded() {
      return this.$store.state.expandedSidebar;
    },
    activeRoute() {
      return this.$route.path;
    },
    displayname() {
      return authModule.displayname;
    },
  },
  methods: {
    toggleSidebar() {
      this.$store.commit('sidebarMutation');
    },
    async logout() {
      const choice = confirm('Are you sure you want to logout?');
      if (!choice) return;
      await authModule.removeAccount();
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  width: 20%;
  background-color: gray;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: width 0.3s ease, opacity 0.3s ease;

  .image-container {
    width: 100%;
    height: 15%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
      cursor: pointer;
    }
  }

  .links-container {
    width: 100%;
    height: 70%;
    display: flex;
    background-color: #50276b;
    flex-direction: column;
    opacity: 1;
    transition: opacity 0.3s ease;

    .nav-link {
      width: 100%;
      background-color: #50276b;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 20px;
      color: white;
      text-decoration: none;

      &:hover {
        background-color: #3e1f5b;
      }

      .icon {
        margin-right: 10px;
      }

      p {
        margin: 0 !important;
      }

      &.active {
        background-color: #3e1f5b;
      }
    }
  }

  .logout {
    width: 100%;
    height: 15%;
    background-color: #50276b;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.minimized {
  width: 10%;
  background-color: #50276b;
  height: 100vh;
  display: flex;
  flex-direction: column;
  transition: width 0.3s ease;

  .image-container {
    width: 100%;
    height: 15%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
      cursor: pointer;
    }
  }

  .icons-container {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .nav-link {
      width: 100%;
      background-color: #50276b;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
      color: white;
      text-decoration: none;

      &:hover {
        background-color: #3e1f5b;
      }

      .icon {
        margin-right: 10px;
      }

      p {
        margin: 0 !important;
      }

      &.active {
        background-color: #3e1f5b;
      }
    }
  }

  .expand-button {
    width: 100%;
    height: 5%;
    background-color: #50276b;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.sidebar-transition-enter-active,
.sidebar-transition-leave-active {
  transition: width 0.3s ease, opacity 0.3s ease;
}

.sidebar-transition-enter,
.sidebar-transition-leave-to {
  width: 0%;
  opacity: 0;
}

@media (max-width: 1000px) {
  .sidebar {
    display: none;
  }

  .minimized {
    display: none;
  }
}
</style>
