<template lang="pug">
div(class="d-flex w-100 " style="gap: 1rem;height: 100vh;")
    NavbarV2
    b-overlay(:show="loading" rounded="sm" spinner-variant="primary" class="px-5 h-100 form-container"  :style="{ width: hidePreview ? '80%' : '60%' }")
        div(class="w-100 mx-auto pt-5 d-flex flex-column align-items-start mb-5 h-100")
            div(class="d-flex  align-items-center justify-content-between w-100 py-4" style="height: fit-content;")
                b-button(class="back-btn d-flex align-items-center mb-3" style="gap:5px;"
                    @click="goBack" variant="outline-primary")
                    b-icon-arrow-left
                    span Back
                div(class="d-flex flex-column align-items-center text-start")
                    h4 Create New Events
                    p Create new events, Single day or Multiple days.
                b-button(@click="hidePreview = !hidePreview" variant="primary" class="") Toggle Preview
            div(class="w-100 d-flex flex-column align-items-start overflow-auto pb-5" style="height: 100%;")
                p.small-text.m-0 Step 1
                h5 General Information
                div(class="hr")
                div(class="step-1 w-full w-100 d-flex flex-column")
                    div(class="input-container-row d-flex justify-content-between w-100")
                        div(class="input-container w-50 d-flex flex-column align-items-start")
                            label(for="name") Event Name
                            b-form-input(v-model="event.name" type="text" id="name" placeholder="Enter Event Name" required)
                        div(class="input-container w-50 d-flex flex-column align-items-start")
                            label(for="group") Select Group
                            v-select( id="event-group"  :options="groups" :reduce="item => ({ id: item.id, name: item.name })"  v-model="event.selectedGroup" multiple label="name" placeholder="Select Group" :class="{'invalid': event.selectedGroup.length === 0}")
                    div(class="input-container w-100 d-flex flex-column align-items-start mt-3")
                            label(for="invoice") Generate Invoice
                            b-form-checkbox(v-model="event.generateInvoice" id="invoice" switch) Generate Invoice
                    div(class="input-container w-100 d-flex flex-column align-items-start mt-3")
                        label(for="remarks") Custom Remarks Placeholder 
                        b-form-checkbox(v-model="customRemarks" id="remarks" switch) Custom Remarks
                    div(class="input-container w-100 d-flex flex-column align-items-start mt-3" v-if="customRemarks")
                        label(for="remarks") Remarks Placeholder 
                        b-form-input(v-model="event.remarksPlaceholder" type="text" id="remarks" placeholder="Enter Remarks Placeholder" required)
                    div(class="input-container w-100 d-flex flex-column align-items-start mt-3")
                        label(for="description") Description
                        vue-editor(v-model="event.description" :editorToolbar="customToolbar" placeholder="Enter Description" required ).w-100
                .hr
                p.small-text.mb-0.mt-5 Step 2
                h5 Event Details
                div(class="hr")
                div(class="step-2 w-full w-100 d-flex flex-column")
                    div(class="tab-container d-flex justify-content-between w-100")
                        div(class="tab-checkbox d-flex justify-content-center align-items-center" @click="event.isSingleDay = true" :class="{'active': event.isSingleDay}") Single Day
                        div(class="tab-checkbox d-flex justify-content-center align-items-center" @click="event.isSingleDay = false" :class="{'active': !event.isSingleDay}") Multiple Days
                    div(v-if="event.isSingleDay")
                        div(class="input-container-row d-flex justify-content-between w-100 mt-2")
                            div(class="input-container w-50 d-flex flex-column align-items-start")
                                label(for="date") Date
                                b-form-datepicker(v-model="event.singleDay.date" id="date" placeholder="Select Date" required)
                            div(class="input-container w-50 d-flex flex-column align-items-start")
                                label(for="time") Time
                                vue-timepicker(v-model="event.singleDay.time" format="hh:mm A" :minute-interval="5" id="time" placeholder="Select Time" required input-width="100%" )
                        div(class="input-container w-100 d-flex flex-column align-items-start mt-3")
                                label(for="price") Price Per Person (Caregiver or Other Person)
                                b-input-group
                                    b-input-group-prepend
                                        span(class="input-group-text") $
                                    b-form-input(v-model="event.singleDay.pricePerPerson" type="number" id="price" placeholder="Enter Price" required)
                        b-collapse(:id="'collapse-itineraries-single-day'" class="w-100")
                            div(class="input-container w-100 d-flex flex-column align-items-start mt-3" style="border: 1px solid #DAE1EB; border-radius: 5px; padding: 10px;")
                                p.font-weight-bold Itineraries Added
                                p(class="small-text" v-if="event.singleDay.itineraries.length === 0") No Itineraries Added
                                div(class="d-flex flex-column align-items-start w-100")
                                    div(v-for="itinerary in event.singleDay.itineraries" :key="itinerary.id" class="d-flex justify-content-between w-100")
                                        div(class="input-container-row d-flex justify-content-between w-100 align-items-end mt-2")
                                            div(class="input-container w-50 d-flex flex-column align-items-start")
                                                label(for="itinerary-time") Time
                                                vue-timepicker(v-model="itinerary.time" format="hh:mm A" :minute-interval="5" id="itinerary-time" placeholder="Select Time" required input-width="100%" )
                                            div(class="input-container w-50 d-flex flex-column align-items-start")
                                                label(for="itinerary-description") Description
                                                b-form-input(v-model="itinerary.description" type="text" id="itinerary-description" placeholder="Enter Description" required)
                                            b-button(variant="danger" @click="removeItinerarySingle(itinerary.id)" class="button-in-row") Remove
                                div(class="hr")
                                p.font-weight-bold.mt-2.mb-0 Add Itinerary (Optional)
                                div(class="input-container-row d-flex justify-content-between w-100 align-items-end")
                                        div(class="input-container w-50 d-flex flex-column align-items-start")
                                            label(for="itinerary-time") Time
                                            vue-timepicker(v-model="tempData.itineraries.time" format="hh:mm A" :minute-interval="5" id="itinerary-time" placeholder="Select Time" required input-width="100%" )
                                        div(class="input-container w-50 d-flex flex-column align-items-start")
                                            label(for="itinerary-description") Description
                                            b-form-input(v-model="tempData.itineraries.description" type="text" id="itinerary-description" placeholder="Enter Description" required @keyup.enter="addItinerary")
                                        b-button(@click="addItinerary" class="button-in-row" variant="success" ) Add
                        b-button(v-b-toggle="'collapse-itineraries-single-day'" class="button-in-row w-100 mt-3" variant="secondary") Toggle Itineraries
                        b-collapse(:id="'collapse-food-single-day'" class="w-100")
                            div(class="input-container w-100 d-flex flex-column align-items-start mt-3" style="border: 1px solid #DAE1EB; border-radius: 5px; padding: 10px;")
                                p.font-weight-bold Food Added
                                p(class="small-text mb-0" v-if="event.singleDay.food.lunch.length === 0") No Lunch Added
                                div(class="d-flex flex-column align-items-start w-100")
                                    div(v-for="lunch in event.singleDay.food.lunch" :key="lunch.id" class="d-flex justify-content-between w-100")
                                        div(class="input-container-row d-flex justify-content-between w-100 align-items-end mt-2")
                                            div(class="input-container w-100 d-flex flex-column align-items-start")
                                                label(for="lunch") Name of Lunch
                                                b-form-input(v-model="lunch.name" type="text" id="lunch" placeholder="Enter Lunch" required)
                                            b-button(variant="danger" @click="removeLunch(lunch.id)") Remove
                                div(class="hr")
                                p.font-weight-bold.mt-2.mb-0 Add Lunch (Optional)
                                div(class="input-container-row d-flex justify-content-between w-100 align-items-end")
                                    div(class="input-container w-75 d-flex flex-column align-items-start")
                                        label(for="lunch") Lunch
                                        b-form-input(v-model="tempData.food.lunch" type="text" id="lunch" placeholder="Enter Lunch" required @keyup.enter="addLunch")
                                    b-button(@click="addLunch" class="button-in-row w-25" variant="success" ) Add
                        b-button(v-b-toggle="'collapse-food-single-day'" class="button-in-row w-100 mt-3" variant="secondary") Toggle Food
                        b-collapse(:id="'collapse-beverages-single-day'" class="w-100")
                            div(class="input-container w-100 d-flex flex-column align-items-start mt-3" style="border: 1px solid #DAE1EB; border-radius: 5px; padding: 10px;")
                                p.font-weight-bold Beverages Added
                                p(class="small-text mb-0" v-if="event.singleDay.food.beverages.length === 0") No Beverages Added
                                div(class="d-flex flex-column align-items-start w-100")
                                    div(v-for="beverage in event.singleDay.food.beverages" :key="beverage.id" class="d-flex justify-content-between w-100")
                                        div(class="input-container-row d-flex justify-content-between w-100 align-items-end mt-2")
                                            div(class="input-container w-100 d-flex flex-column align-items-start")
                                                label(for="beverage") Name of Beverage
                                                b-form-input(v-model="beverage.name" type="text" id="beverage" placeholder="Enter Beverage" required)
                                            b-button(variant="danger" @click="removeBeverage(beverage.id)") Remove
                                div(class="hr")
                                p.font-weight-bold.mt-2.mb-0 Add Beverage (Optional)
                                div(class="input-container-row d-flex justify-content-between w-100 align-items-end")
                                    div(class="input-container w-75 d-flex flex-column align-items-start")
                                        label(for="beverage") Beverage
                                        b-form-input(v-model="tempData.food.beverage" type="text" id="beverage" placeholder="Enter Beverage" required @keyup.enter="addBeverage")
                                    b-button(@click="addBeverage" class="button-in-row w-25" variant="success" ) Add
                        b-button(v-b-toggle="'collapse-beverages-single-day'" class="button-in-row w-100 mt-3" variant="secondary") Toggle Beverages
                
                    div(v-else)
                        div(class="input-container w-100 d-flex flex-column align-items-start mt-3" style="border: 1px solid #DAE1EB; border-radius: 5px; padding: 10px;")
                            p.font-weight-bold Multiple Days
                            p(class="small-text mb-0" v-if="event.multipleEvents.length === 0") No Days Added
                            div(class="d-flex flex-column align-items-start w-100" v-else style="gap: 10px;")
                                div(v-for="day in event.multipleEvents" :key="day.id" class="d-flex flex-column w-100 align-items-start" style="gap: 10px;border: 1px solid #DAE1EB; border-radius: 5px; padding: 10px;")
                                    div(class="input-container-row d-flex justify-content-between w-100 align-items-end mt-2")
                                        p Day {{ event.multipleEvents.indexOf(day) + 1 }}
                                        b-button(variant="danger d-flex align-items-center" @click="removeDayFromMulti(day.id)" style="height: fit-content; gap: 5px;")
                                            b-icon-trash
                                            span Remove
                                    div(class="input-container-row d-flex justify-content-between w-100 align-items-end mt-2")
                                        div(class="input-container w-50 d-flex flex-column align-items-start")
                                            label(for="day-title") Day Title
                                            b-form-input(v-model="day.title" type="text" id="day-title" placeholder="Enter Day Title" required)
                                        div(class="input-container w-50 d-flex flex-column align-items-start")
                                            label(for="day-date") Date
                                            b-form-datepicker(v-model="day.date" id="day-date" placeholder="Select Date" required)
                                    div(class="input-container-row d-flex justify-content-between w-100 align-items-end mt-2")
                                        div(class="input-container w-50 d-flex flex-column align-items-start")
                                            label(for="day-time") Time
                                            vue-timepicker(v-model="day.time" format="hh:mm A" :minute-interval="5" id="day-time" placeholder="Select Time" required input-width="100%" )
                                        div(class="input-container w-50 d-flex flex-column align-items-start")
                                            label(for="day-price") Price Per Person (Caregiver or Other Person)
                                            b-input-group
                                                b-input-group-prepend
                                                    span(class="input-group-text") $
                                                b-form-input(v-model="day.pricePerPerson" type="number" id="day-price" placeholder="Enter Price" required)
                                    b-collapse(:id="'collapse-itineraries-' + day.id" class="w-100")
                                        div(class="input-container w-100 d-flex flex-column align-items-start mt-3 p-2")
                                            p.font-weight-bold Itineraries Added
                                            p(class="small-text" v-if="day.itineraries.length === 0") No Itineraries Added
                                            div(class="d-flex flex-column align-items-start w-100")
                                                div(v-for="itinerary in day.itineraries" :key="itinerary.id" class="d-flex justify-content-between w-100")
                                                    div(class="input-container-row d-flex justify-content-between w-100 align-items-end mt-2")
                                                        div(class="input-container w-50 d-flex flex-column align-items-start")
                                                            label(for="itinerary-time") Time
                                                            vue-timepicker(v-model="itinerary.time" format="hh:mm A" :minute-interval="5" id="itinerary-time" placeholder="Select Time" required input-width="100%" )
                                                        div(class="input-container w-50 d-flex flex-column align-items-start")
                                                            label(for="itinerary-description") Description
                                                            b-form-input(v-model="itinerary.description" type="text" id="itinerary-description" placeholder="Enter Description" required)
                                                        b-button(variant="danger" @click="removeItineraryMulti(itinerary.id, day.id)" class="button-in-row") Remove
                                            div(class="hr")
                                            p.font-weight-bold.mt-2.mb-0 Add Itinerary (Optional)
                                            div(class="input-container-row d-flex justify-content-between w-100 align-items-end")
                                                    div(class="input-container w-50 d-flex flex-column align-items-start")
                                                        label(for="itinerary-time") Time
                                                        vue-timepicker(v-model="tempData.itineraries.time" format="hh:mm A" :minute-interval="5" id="itinerary-time" placeholder="Select Time" required input-width="100%" )
                                                    div(class="input-container w-50 d-flex flex-column align-items-start")
                                                        label(for="itinerary-description") Description
                                                        b-form-input(v-model="tempData.itineraries.description" type="text" id="itinerary-description" placeholder="Enter Description" required @keyup.enter="addItineraryMultipleDay(day.id)")
                                                    b-button(@click="addItineraryMultipleDay(day.id)" class="button-in-row" variant="success" ) Add
                                            div(class="input-container w-100 d-flex flex-column align-items-start mt-3 p-2")
                                                p.font-weight-bold Food Added
                                                p(class="small-text" v-if="day.food.lunch.length === 0") No Lunch Added
                                                div(class="d-flex flex-column align-items-start w-100")
                                                    div(v-for="lunch in day.food.lunch" :key="lunch.id" class="d-flex justify-content-between w-100")
                                                        div(class="input-container-row d-flex justify-content-between w-100 align-items-end mt-2")
                                                            div(class="input-container w-100 d-flex flex-column align-items-start")
                                                                label(for="lunch") Name of Lunch
                                                                b-form-input(v-model="lunch.name" type="text" id="lunch" placeholder="Enter Lunch" required)
                                                            b-button(variant="danger" @click="removeLunchMulti(lunch.id, day.id)") Remove
                                                div(class="hr")
                                                p.font-weight-bold.mt-2.mb-0 Add Lunch (Optional)
                                                div(class="input-container-row d-flex justify-content-between w-100 align-items-end")
                                                    div(class="input-container w-75 d-flex flex-column align-items-start")
                                                        label(for="lunch") Lunch
                                                        b-form-input(v-model="tempData.food.lunch" type="text" id="lunch" placeholder="Enter Lunch" required @keyup.enter="addLunchMulti(day.id)")
                                                    b-button(@click="addLunchMulti(day.id)" class="button-in-row w-25" variant="success" ) Add
                                            div(class="input-container w-100 d-flex flex-column align-items-start mt-3 p-2")
                                                p.font-weight-bold Beverages Added
                                                p(class="small-text" v-if="day.food.beverages.length === 0") No Beverages Added
                                                div(class="d-flex flex-column align-items-start w-100")
                                                    div(v-for="beverage in day.food.beverages" :key="beverage.id" class="d-flex justify-content-between w-100")
                                                        div(class="input-container-row d-flex justify-content-between w-100 align-items-end mt-2")
                                                            div(class="input-container w-100 d-flex flex-column align-items-start")
                                                                label(for="beverage") Name of Beverage
                                                                b-form-input(v-model="beverage.name" type="text" id="beverage" placeholder="Enter Beverage" required)
                                                            b-button(variant="danger" @click="removeBeverageMulti(beverage.id, day.id)") Remove
                                                    div(class="hr")
                                                    p.font-weight-bold.mt-2.mb-0 Add Beverage (Optional)
                                                    div(class="input-container-row d-flex justify-content-between w-100 align-items-end")
                                                        div(class="input-container w-75 d-flex flex-column align-items-start")
                                                            label(for="beverage") Beverage
                                                            b-form-input(v-model="tempData.food.beverage" type="text" id="beverage" placeholder="Enter Beverage" required @keyup.enter="addBeverageMulti(day.id)")
                                                        b-button(@click="addBeverageMulti(day.id)" class="button-in-row w-25" variant="success" ) Add
                                    b-button(v-b-toggle="'collapse-itineraries-' + day.id" class="button-in-row w-100 mt-3" variant="secondary") Toggle Details for - Day {{ event.multipleEvents.indexOf(day) + 1 }}
                        b-button(@click="showDayAddModal = true" class="button-in-row w-100 mt-3" variant="success" ) Add Day
                    div(class="input-container-row d-flex justify-content-between w-100 align-items-end")
                        div(class="input-container w-100 d-flex flex-column align-items-start")
                            label(for="gst") Edit GST
                            b-form-checkbox(v-model="event.editGST" id="gst" switch) Edit GST
                        div(class="input-container w-100 d-flex flex-column align-items-start mt-3")
                            label(for="gst") GST
                            b-input-group
                                b-input-group-prepend
                                    span(class="input-group-text") %
                                b-form-input(v-model="event.gst" type="number" id="gst" placeholder="Enter GST" required :readonly="!event.editGST")
                    div(class="input-container w-100 d-flex flex-column align-items-start mt-3")
                        label(for="total") Total
                        b-input-group
                            b-input-group-prepend
                                span(class="input-group-text") $
                            b-form-input(:value="calculateTotal" type="number" id="total" placeholder="Total" readonly)
                    b-button(@click="submit" class="button-in-row w-100 mt-3" variant="primary") Submit
            
    PreviewEventCreation(:event.sync="event" v-if="!hidePreview")
    AddMultipleDays(:show.sync="showDayAddModal" @close= "showDayAddModal = false" @addDays="addDayToMulti" )
    



</template>

<script>
/* eslint-disable */
import NavbarV2 from "../components/NavbarV2.vue";
import { v4 as uuidv4 } from "uuid";
import { VueEditor } from "vue2-editor";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import Header from "../components/Header.vue";
import axios from "axios";
import { mapActions } from 'vuex';
import PreviewEventCreation from "../components/PreviewEventCreation.vue";
import AddMultipleDays from "../components/eventModals/AddMultipleDays.vue";
export default {
    components: {
        VueEditor,
        VueTimepicker,
        Header,
        NavbarV2,
        PreviewEventCreation,
        AddMultipleDays,
    },
    data() {
        return {
            showDayAddModal: false,
            customToolbar: [
                ["bold", "italic", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" },
                ],
                ["link"],
                ["clean"],
                ['image']
            ],
            event: {
                name: "",
                selectedGroup: "",
                description: "",
                isSingleDay: true,
                multipleEvents: [],
                generateInvoice: true,
                singleDay: {
                    date: null,
                    time: null,
                    itineraries: [],
                    food: {
                        lunch: [],
                        beverages: [],
                    },
                    pricePerPerson: 0,
                },
                gst: 9,
                editGST: false,
                total: 0,
                remarksPlaceholder: "Any Remarks",
                
            },
            customRemarks: false,
            tempData: {
                date: null,
                time: null,
                itineraries: {
                    time: "",
                    description: "",
                },
                food: {
                    lunch: "",
                    beverage: "",
                },
            },
            loading: false,
            groups: [],
            hidePreview: false,
        };
    },
    computed: {
        showModal: {
            get() {
                return this.show;
            },
            set(value) {
                this.$emit("update:show", value);
            },
        },
        calculateTotal() {
            let total = 0;
            if (this.event.singleDay.pricePerPerson) {
                total = Number(this.event.singleDay.pricePerPerson) || 0;
            } else {
                total = this.event.multipleEvents.reduce(
                    (item, day) => item + (Number(day.pricePerPerson) || 0),
                    0
                );
            }
            if (this.event.gst) {
                total = total + (total * (Number(this.event.gst) || 0) / 100);
            }
            return Number(total).toFixed(2);
        },
    },
    methods: {
        goBack() {
            this.$router.push({ name: "EventsPage" });
        },
        removeDayFromMulti(id) {
            let choice = confirm("Are you sure you want to delete this day?");
            if (!choice) return;
            console.log(id);
            let index = this.event.multipleEvents.findIndex((day) => day.id === id);
            if (index !== -1) {
                this.event.multipleEvents.splice(index, 1);
            }
        },
        ...mapActions(["getEveryGroup"]),
        async submit() {
            let choice
            if(!this.event.generateInvoice){
                choice = confirm("Are you sure you want to create events without generating an invoice?");  
                if(!choice) return;
            }
            if (this.event.isSingleDay) {
                if (
                    !this.event.name ||
                    !this.event.selectedGroup ||
                    !this.event.description ||
                    !this.event.singleDay.date ||
                    !this.event.singleDay.time
                ) {
                    this.$bvToast.toast("Please fill all the fields", {
                        title: "Error",
                        variant: "danger",
                        solid: true,
                    });
                    return;
                }
                this.loading = true;
                const eventData = {
                    name: this.event.name,
                    recipient: this.event.selectedGroup,
                    date: this.event.singleDay.date,
                    json: {
                        id: uuidv4(),
                        ...this.event,
                    },
                    endDate: this.event.singleDay.date,
                    crb5c_invoicegeneration: this.event.generateInvoice
                };
                let date = new Date(this.event.singleDay.date);
                let dateStr = date.toISOString();
                const res = await axios.post("/api/events/v2", eventData);
                if (res.status === 200) {
                    this.$bvToast.toast("Event Added Successfully", {
                        title: "Success",
                        variant: "success",
                        solid: true,
                    });
                    this.showModal = false;
                    this.event = {
                        name: "",
                        selectedGroup: "",
                        description: "",
                        isSingleDay: true,
                        multipleEvents: [],
                        singleDay: {
                            date: null,
                            time: null,
                            itineraries: [],
                            food: {
                                lunch: [],
                                beverages: [],
                            },
                            pricePerPerson: 0,
                        },
                        gst: 1.09,
                        editGST: false,
                        total: 0,
                    };
                    this.tempData = {
                        date: null,
                        time: null,
                        itineraries: {
                            time: "",
                            description: "",
                        },
                        food: {
                            lunch: "",
                            beverage: "",
                        },
                    };
                    this.showModal = false;
                    
                    this.$router.push({ name: "EventsPage" , query: { date:encodeURIComponent(dateStr)  }});
                } else {
                    this.$bvToast.toast("Error Adding Event", {
                        title: "Error",
                        variant: "danger",
                        solid: true,
                    });
                }
                this.loading = false;
            } else {
                if (
                    !this.event.name ||
                    !this.event.selectedGroup ||
                    !this.event.description ||
                    this.event.multipleEvents.length === 0
                ) {
                    this.$bvToast.toast("Please fill all the fields", {
                        title: "Error",
                        variant: "danger",
                        solid: true,
                    });
                    return;
                }
                if (
                    this.event.multipleEvents.some(
                        (day) => !day.title || !day.date || !day.time
                    )
                ) {
                    this.$bvToast.toast("Please fill all the fields", {
                        title: "Error",
                        variant: "danger",
                        solid: true,
                    });
                    return;
                }
                this.loading = true;
                const eventData = {
                    name: this.event.name,
                    recipient: this.event.selectedGroup,
                    json: {
                        id: uuidv4(), // need to delete this
                        ...this.event,
                    },
                    date: this.event.multipleEvents[0].date,
                    endDate: this.event.multipleEvents[this.event.multipleEvents.length - 1].date,
                    crb5c_invoicegeneration: this.event.generateInvoice
                };
                let date = new Date(this.event.multipleEvents[0].date);
                let dateStr = date.toISOString();
                const res = await axios.post("/api/events/v2", eventData);
                if (res.status === 200) {
                    this.$bvToast.toast("Event Added Successfully", {
                        title: "Success",
                        variant: "success",
                        solid: true,
                    });
                    this.showModal = false;
                    this.event = {
                        name: "",
                        selectedGroup: "",
                        description: "",
                        isSingleDay: true,
                        multipleEvents: [],
                        singleDay: {
                            date: null,
                            time: null,
                            itineraries: [],
                            food: {
                                lunch: [],
                                beverages: [],
                            },
                            pricePerPerson: 0,
                        },

                        gst: 1.09,
                        editGST: false,
                        total: 0,
                    };
                    this.tempData = {
                        date: null,
                        time: null,
                        itineraries: {
                            time: "",
                            description: "",
                        },
                        food: {
                            lunch: "",
                            beverage: "",
                        },
                    };
                    this.showModal = false;
                    this.$router.push({ name: "EventsPage" , query: { date:encodeURIComponent(dateStr)  }});

                } else {
                    this.$bvToast.toast("Error Adding Event", {
                        title: "Error",
                        variant: "danger",
                        solid: true,
                    });
                }
                this.loading = false;
            }
        },
        removeBeverageMulti(id, dayId) {
            let index = this.event.multipleEvents.findIndex(
                (day) => day.id === dayId
            );
            if (index !== -1) {
                let beverageIndex = this.event.multipleEvents[
                    index
                ].food.beverages.findIndex((beverage) => beverage.id === id);
                if (beverageIndex !== -1) {
                    this.event.multipleEvents[index].food.beverages.splice(
                        beverageIndex,
                        1
                    );
                }
            }
        },
        addBeverageMulti(id) {
            let index = this.event.multipleEvents.findIndex((day) => day.id === id);
            if (index !== -1) {
                if (!this.tempData.food.beverage) {
                    this.$bvToast.toast("Please fill all the fields", {
                        title: "Error",
                        variant: "danger",
                        solid: true,
                    });
                    return;
                }
                this.event.multipleEvents[index].food.beverages.push({
                    id: uuidv4(),
                    name: this.tempData.food.beverage,
                });
                this.tempData.food.beverage = "";
            }
        },
        removeLunchMulti(id, dayId) {
            let index = this.event.multipleEvents.findIndex(
                (day) => day.id === dayId
            );
            if (index !== -1) {
                let lunchIndex = this.event.multipleEvents[index].food.lunch.findIndex(
                    (lunch) => lunch.id === id
                );
                if (lunchIndex !== -1) {
                    this.event.multipleEvents[index].food.lunch.splice(lunchIndex, 1);
                }
            }
        },
        addLunchMulti(id) {
            let index = this.event.multipleEvents.findIndex((day) => day.id === id);
            if (index !== -1) {
                if (!this.tempData.food.lunch) {
                    this.$bvToast.toast("Please fill all the fields", {
                        title: "Error",
                        variant: "danger",
                        solid: true,
                    });
                    return;
                }
                this.event.multipleEvents[index].food.lunch.push({
                    id: uuidv4(),
                    name: this.tempData.food.lunch,
                });
                this.tempData.food.lunch = "";
            }
        },
        removeItineraryMulti(id, dayId) {
            let index = this.event.multipleEvents.findIndex(
                (day) => day.id === dayId
            );
            if (index !== -1) {
                let itineraryIndex = this.event.multipleEvents[
                    index
                ].itineraries.findIndex((itinerary) => itinerary.id === id);
                if (itineraryIndex !== -1) {
                    this.event.multipleEvents[index].itineraries.splice(
                        itineraryIndex,
                        1
                    );
                }
            }
        },
        addItineraryMultipleDay(id) {
            let index = this.event.multipleEvents.findIndex((day) => day.id === id);
            if (index !== -1) {
                if (
                    !this.tempData.itineraries.time ||
                    !this.tempData.itineraries.description
                ) {
                    this.$bvToast.toast("Please fill all the fields", {
                        title: "Error",
                        variant: "danger",
                        solid: true,
                    });
                    return;
                }
                this.event.multipleEvents[index].itineraries.push({
                    id: uuidv4(),
                    time: this.tempData.itineraries.time,
                    description: this.tempData.itineraries.description,
                });
                this.tempData.itineraries.time = "";
                this.tempData.itineraries.description = "";
            }
        },
        addDayToMulti(data) {
            this.event.multipleEvents.push({
                id: uuidv4(),
                title: data.title,
                date: data.date,
                time: data.time,
                pricePerPerson: data.pricePerPerson,
                itineraries: data?.itineraries,
                food: {
                    lunch: data?.food?.lunch || [],
                    beverages: data?.food?.beverages || [],
                },
            });
        },
        removeBeverage(id) {
            let choice = confirm("Are you sure you want to delete this beverage?");
            if (!choice) return;
            let index = this.event.singleDay.food.beverages.findIndex(
                (beverage) => beverage.id === id
            );
            if (index !== -1) {
                this.event.singleDay.food.beverages.splice(index, 1);
            }
        },
        addBeverage() {
            if (!this.tempData.food.beverage) {
                this.$bvToast.toast("Please fill all the fields", {
                    title: "Error",
                    variant: "danger",
                    solid: true,
                });
                return;
            }
            this.event.singleDay.food.beverages.push({
                id: uuidv4(),
                name: this.tempData.food.beverage,
            });
            this.tempData.food.beverage = "";
        },
        removeLunch(id) {
            let choice = confirm("Are you sure you want to delete this lunch?");
            if (!choice) return;
            let index = this.event.singleDay.food.lunch.findIndex(
                (lunch) => lunch.id === id
            );
            if (index !== -1) {
                this.event.singleDay.food.lunch.splice(index, 1);
            }
        },
        addLunch() {
            if (!this.tempData.food.lunch) {
                this.$bvToast.toast("Please fill all the fields", {
                    title: "Error",
                    variant: "danger",
                    solid: true,
                });
                return;
            }
            this.event.singleDay.food.lunch.push({
                id: uuidv4(),
                name: this.tempData.food.lunch,
            });
            this.tempData.food.lunch = "";
        },
        removeItinerarySingle(id) {
            let choice = confirm("Are you sure you want to delete this itinerary?");
            if (!choice) return;
            let index = this.event.singleDay.itineraries.findIndex(
                (itinerary) => itinerary.id === id
            );
            if (index !== -1) {
                this.event.singleDay.itineraries.splice(index, 1);
            }
        },
        addItinerary() {
            if (
                !this.tempData.itineraries.time ||
                !this.tempData.itineraries.description
            ) {
                this.$bvToast.toast("Please fill all the fields", {
                    title: "Error",
                    variant: "danger",
                    solid: true,
                });
                return;
            }
            this.event.singleDay.itineraries.push({
                id: uuidv4(),
                time: this.tempData.itineraries.time,
                description: this.tempData.itineraries.description,
            });
            this.tempData.itineraries.time = "";
            this.tempData.itineraries.description = "";
        },
    },
    async mounted() {
        this.loading = true;
        try{
        const { sessions } = await this.getEveryGroup();

        if (!sessions && sessions.length === 0) {
            return;
        }

        const sessionMap = sessions.map((item) => ({
            id: item.crb5c_fow_session_scheduleid,
            name: item.crb5c_session_id,
        }));
        const sortListGroupSession = this.$root.sortSessionGroupNameByDay(sessionMap);
        sortListGroupSession.unshift({ id: 'All', name: 'All' });
        this.groups = sortListGroupSession;
        let event = localStorage.getItem("event");
        if (event) {
            const choice = confirm(`Do you want to continue with the previous event, Title: ${JSON.parse(event).name}`);
            if (choice) {
                this.event = JSON.parse(event);
            } else {
                localStorage.removeItem("event");
            }

        }
    }catch(e){
        console.log(e);
    }finally{
        this.loading = false;
    }

    },
    watch: {
        event: {
            handler: function (newVal) {
                localStorage.setItem("event", JSON.stringify(newVal));
            },
            deep: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.form-container{
    transition: all 0.3s;
}
.small-text {
    font-size: 15px;
    color: #6c757d;
}

.hr {
    height: 1px;
    background-color: #DAE1EB;
    width: 100%;
    margin: 10px 0;
}

.input-container-row {
    gap: 10px;
}

#event-group {
    width: 100%;

    input {
        width: 100%;
        height: 100%;
    }
}

.tab-container {
    gap: 10px;
}

.tab-checkbox {
    width: 50%;
    padding: 10px 0;
    border: 1px solid #DAE1EB;
    border-radius: 5px;
    background-color: #fff;
    color: #6c757d;
    font-size: 15px;
    cursor: pointer;
}

.active {
    background-color: #8967b3;
    color: #fff;
}

.button-in-row {
    height: fit-content;
    border: none !important;
    outline: none !important;
}

</style>
