<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    
    <!-- <transition name="zoom-fade" mode="out-in">
      <router-view></router-view>
    </transition> -->
    <router-view></router-view>

  </div>
</template>

<script>

export default {
  async mounted() {
  },
  data() {
        return {
        }
    },
  created() {
    },
  methods: {
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/vendors/bootstrap-vue/index";
// .zoom-fade-enter-active, .zoom-fade-leave-active {
//   transition: transform 0.5s ease, opacity 0.5s ease;
// }
// .zoom-fade-enter, .zoom-fade-leave-to {
//   transform: scale(0.9);
//   opacity: 0;
// }
// .zoom-fade-enter-to, .zoom-fade-leave {
//   transform: scale(1);
//   opacity: 1;
// }
:root{
  --btn-color: rgb(118, 80, 137);
}
*{
  font-size: 16px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
